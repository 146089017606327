import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Asia/Shanghai')

// 时间转换为大陆时区
export function daysjToStr (date: string) {
  return dayjs.tz(date)
    .valueOf()
    .toString()
}
